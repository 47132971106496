import moment from "moment";

export default {
  name: "SmartTable",
  data() {
    return {
      search: "",
      dialogVendor: false,
      dialogOtic: false,
      dialogRequest: false,
      dialogRequestSelected: null,
      dialogItemsVendor: [],
      dialogItemsOtic: [],
      dialogTitle: "",
      dialogDataType: null,
      checkboxListVendor: [],
      checkboxListOtic: [],
      tempItems: [],
    };
  },
  props: {
    headers: Array,
    items: Array,
    loading: Boolean,
  },
  methods: {
    onVendorClick() {
      this.dialogVendor = true;

      this.dialogTitle = "Vendor";
      this.dialogDataType = "vendorName";

      let result = [];

      this.items.forEach((item) => {
        item.data.vendors.forEach((element) => {
          console.warn(element);

          if (!result.find((x) => x.name == element.name)) {
            result.push(element);
          }
        });

        this.dialogItemsVendor = result;
      });
    },

    onOticClick() {
      this.dialogOtic = true;

      this.dialogTitle = "OTIC";
      this.dialogDataType = "oticName";

      let result = [];

      this.items.forEach((item, index) => {
        if (!result.find((x) => x.name == item.data.oticName)) {
          result.push({
            id: index.toString(),
            name: item.data.oticName,
          });
        }
      });

      this.dialogItemsOtic = result;
    },

    onEmailReguest(item) {
      this.dialogRequestSelected = item;
      this.dialogRequest = true;
    },

    onCancelEmailRequest() {
      this.dialogRequestSelected = null;
      this.dialogRequest = false;
    },

    onSearchItemClick() {},

    onEmailRequestAction(item) {
      window.location.href =
        "mailto:otic_report@o-ran.org?subject=Request a detailed report from OTIC&body=" +
        this.createText(item, "%0D%0A", "email");
    },

    onCopyAction(item) {
      const text = this.createText(item, "\n", "copy");

      navigator.clipboard.writeText(text);
      this.dialogRequest = false;
    },

    createText(item, breakSign, target) {
      let text = "";

      (text += "Hello O-RAN office," + breakSign),
        (text +=
          "please process the following request." + breakSign + breakSign);

      text += "-------------- Please fill in -------------" + breakSign;
      text += "Requester's name:" + breakSign;
      text += "Requester's company:" + breakSign;
      text +=
        "-------------- Please fill in -------------" + breakSign + breakSign;

      text +=
        "Certificate/Badge ID: " + item.data.certificateBadgeId + breakSign;

      text +=
        "Date of issued certificate yyyy-mm-dd: " +
        moment(item.data.issued).format("YYYY-MM-DD") +
        breakSign;

      text += "Type: " + item.data.certificateBadgeId + breakSign;

      text += "Interface under test: " + item.data.interfaceTest + breakSign;

      text += "RAT under test: " + item.data.technology + breakSign;

      text += "Device under test: " + item.data.category + breakSign;

      text +=
        "Vendor name: " +
        (target == "email"
          ? encodeURIComponent(item.data.vendors.map((x) => x.name).join(", "))
          : item.data.vendors.map((x) => x.name).join(", ")) +
        breakSign;

      text += "Model name: " + item.data.modelName + breakSign;

      text += "Firmware release: " + item.data.releaseFw + breakSign;

      text += "Software release: " + item.data.releaseSw + breakSign;

      text += "Hardware release: " + item.data.releaseHw + breakSign;

      text +=
        "OTIC name: " +
        (target == "email"
          ? encodeURIComponent(item.data.oticName)
          : item.data.oticName) +
        " " +
        breakSign +
        breakSign;

      text +=
        "Please make sure you use your company email address to submit this request. O-RAN ALLIANCE will verify each request. Please note the summary and test reports can only be shared with O-RAN Members (i.e. mobile network operators). Sharing with other O-RAN and non-O-RAN entities is possible only at the discretion of all copyright holders (i.e. the OTIC and all vendors participated in the certification or badging testing)." +
        breakSign +
        breakSign;

      return text;
    },

    onSubmitSearch() {
      let temp = [];

      switch (this.dialogTitle) {
        case "Vendor":
          if (this.checkboxListVendor.length == 0) {
            this.onClearFilter();
            this.dialogVendor = false;
            return;
          }

          this.checkboxListVendor.forEach((item) => {
            this.items.forEach((element) => {
              var result = element.data.vendors.find((x) => x.id == item);

              if (result) {
                temp.push(element);
              }
            });
          });
          break;

        case "OTIC":
          if (this.checkboxListOtic.length == 0) {
            this.onClearFilter();
            this.dialogOtic = false;
            return;
          }

          this.checkboxListOtic.forEach((item) => {
            if (item) {
              this.items.forEach((element) => {
                if (element.data.oticName == item) {
                  temp.push(element);
                }
              });
            }
          });
          break;
      }

      this.tempItems = temp;

      this.checkboxListVendor = [];
      this.checkboxListOtic = [];
      (this.dialogItemsOtic = []),
        (this.dialogItemsVendor = []),
        (this.dialogVendor = false);
      this.dialogOtic = false;
    },

    onClearFilter() {
      this.search = "";
      this.checkboxListVendor = [];
      this.checkboxListOtic = [];
      this.dialogItemsOtic = [];
      this.dialogItemsVendor = [];
      this.tempItems = JSON.parse(JSON.stringify(this.items));
    },

    onCancel() {
      this.checkboxListVendor = [];
      this.checkboxListOtic = [];
      this.dialogItemsOtic = [];
      this.dialogItemsVendor = [];
      this.dialogVendor = false;
      this.dialogOtic = false;
      this.dialog = false;
      this.tempItems = JSON.parse(JSON.stringify(this.items));
    },
  },
  created() {
    setTimeout(() => {
      this.tempItems = JSON.parse(JSON.stringify(this.items));
    }, 1000);
  },
};
