import Setting from "../../setting.js";
import SmartTable from "@/components/SmartTable.vue";

export default {
  name: "HomeView",

  components: {
    SmartTable,
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "Certification / Badge ID",
          align: "start",
          sortable: true,
          value: "data.certificateBadgeId",
        },
        {
          text: "Date of issued certificate yyy-mm-dd",
          align: "start",
          width: "10%",
          sortable: true,
          value: "data.issued",
        },
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "data.type",
        },
        {
          text: "Interface under test",
          align: "start",
          sortable: true,
          value: "data.interfaceTest",
        },
        {
          text: "RAT under test",
          align: "start",
          sortable: true,
          value: "data.technology",
        },
        {
          text: "Device under test",
          align: "start",
          sortable: true,
          value: "data.category",
        },
        {
          text: "Vendor name",
          align: "start",
          sortable: true,
          value: "data.vendorName",
        },
        {
          text: "Model name",
          align: "start",
          sortable: true,
          value: "data.modelName",
        },
        {
          text: "FW release",
          align: "start",
          sortable: false,
          value: "data.releaseFw",
        },
        {
          text: "SW release",
          align: "start",
          sortable: false,
          value: "data.releaseSw",
        },
        {
          text: "HW release",
          align: "start",
          sortable: false,
          value: "data.releaseHw",
        },
        {
          text: "OTIC name",
          align: "start",
          sortable: true,
          value: "data.oticName",
        },
        {
          text: "Detailed report",
          align: "start",
          sortable: false,
          value: "data.storageLink",
        },
      ],
      items: [],
    };
  },
  created() {
    this.loading = true;

    this.axios
      .get(Setting[process.env.NODE_ENV].API + "Certification/GetAll")
      .then((response) => {
        this.items = response.data;
        this.loading = false;
      });
  },
};
